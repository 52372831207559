<template>
  <div class="home">
    <el-hearder>
      <TopNav />
    </el-hearder>
    <el-container class="bottom">
      <el-side style="background: white; width: 12%">
        <el-affix offset="1rem">
          <!-- 将侧边栏固定住 -->
          <SideNav />
        </el-affix>
      </el-side>
      <el-main style="padding: 0px">
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import TopNav from "../components/Common/TopNav.vue";
import SideNav from "../components/Common/SideNav.vue";
export default {
  name: "Home",
  components: {
    TopNav,
    SideNav,
  },
  // mounted(){
  //   this.$router.push('/clusterList')
  // }
};
</script>

<style lang = 'less' scoped>
.slide {
  background: brown;
}
</style>
